<ng-container *ngIf="this.isLoading">
  <main
    class="absolute left-0 top-[65px] flex h-[calc(100%-65px)] w-full items-center justify-center bg-black bg-opacity-50"
  >
    <img src="../../../assets/infinite-spinner.svg" class="h-16" />
  </main>
</ng-container>
<ng-container *ngIf="!this.isLoading">
  <div>
    <section class="flex flex-col items-center justify-center">
      <div
        class="m-4 w-full max-w-sm rounded-lg border border-gray-700 bg-gray-900 shadow"
      >
        <div class="relative flex flex-col items-center p-4">
          <span
            *ngIf="bcmPlayerSummary?.lastSync"
            class="absolute right-1 top-1 text-xs opacity-25"
            >Updated:
            {{ bcmPlayerSummary?.lastSync | date : "MM/dd/yy" : "UTC" }}</span
          >
          <span
            *ngIf="!bcmPlayerSummary?.lastSync"
            class="absolute right-1 top-1 text-xs opacity-25"
            >Updated: Waiting...</span
          >
          <img
            class="mb-3 h-24 w-24 rounded-full shadow-lg"
            src="{{ bcmPlayerSummary.user.avatar }}"
            (error)="handleImageLoad($event)"
          />
          <h5 class="mb-1 text-xl font-medium text-white">
            <a
              href="https://www.trueachievements.com/gamercomparison.aspx?gamerid={{
                bcmPlayerSummary.trueAchievementId
              }}&friendid=1480739"
              target="_blank"
              class="hover:text-green-400 hover:underline"
              >{{ bcmPlayerSummary.user.gamertag }}</a
            >
            <ng-container *ngIf="bcmPlayerSummary.bcmStats">
              (<span
                *ngIf="bcmPlayerSummary.bcmStats?.rank === 1"
                class="font-bold text-yellow-400"
                >{{ bcmPlayerSummary.bcmStats?.rank | ordinal }}
              </span>
              <span
                *ngIf="bcmPlayerSummary.bcmStats?.rank === 2"
                class="font-bold text-slate-500"
                >{{ bcmPlayerSummary.bcmStats?.rank | ordinal }}</span
              >
              <span
                *ngIf="bcmPlayerSummary.bcmStats?.rank === 3"
                class="font-bold text-amber-800"
                >{{ bcmPlayerSummary.bcmStats?.rank | ordinal }}</span
              >
              <span
                *ngIf="
                  bcmPlayerSummary.bcmStats?.rank !== 1 &&
                  bcmPlayerSummary.bcmStats?.rank !== 2 &&
                  bcmPlayerSummary.bcmStats?.rank !== 3 &&
                  bcmPlayerSummary.bcmStats?.rank
                "
                >{{ bcmPlayerSummary.bcmStats?.rank | ordinal }}</span
              >)</ng-container
            >
            <ng-container *ngIf="!bcmPlayerSummary.bcmStats"
              >(unranked)</ng-container
            >
          </h5>
          <span
            class="text-xs text-gray-400"
            *ngIf="bcmPlayerSummary.user.region && bcmPlayerSummary.user.area"
            >{{ bcmPlayerSummary.user.region }} -
            {{ bcmPlayerSummary.user.area }}</span
          >
        </div>
        <div class="p flex flex-col items-center pb-4 text-gray-300">
          <span
            >Total Score:
            {{
              bcmPlayerSummary.bcmStats?.totalPoints ?? 0 | number : "1.0-0"
            }}</span
          >
        </div>
      </div>
    </section>

    <div class="flex flex-row flex-wrap items-start justify-center gap-6">
      <section
        class="min-w-[14rem] max-w-md rounded-lg border border-gray-700 bg-gray-800 p-6 shadow"
      >
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
          Completions
        </h5>
        <ng-container *ngIf="gamesLoading">
          <main class="flex justify-center">
            <img
              src="../../../assets/infinite-spinner.svg"
              class="my-8 block h-8"
            />
          </main>
        </ng-container>
        <ng-container *ngIf="!gamesLoading">
          <div class="mb-3 font-normal text-gray-400">
            <div class="mb-3 text-sm font-normal text-gray-400">
              <div class="flex justify-between">
                <span class="w-24 opacity-50">Total #:</span>
                <span class="ml-2">{{ gamesSummary.games.length ?? 0 }}</span>
              </div>
              <div class="flex justify-between">
                <span class="w-24 opacity-50">Avg Ratio:</span>
                <span class="ml-2">{{
                  gamesSummary?.avgRatio ?? 0 | number : "1.3-3"
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="w-24 opacity-50">Highest Ratio:</span>
                <span class="ml-2">{{
                  gamesSummary?.highestRatio ?? 0 | number : "1.3-3"
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="w-24 opacity-50">Avg Length:</span>
                <span class="ml-2"
                  >{{
                    gamesSummary?.avgTime ?? 0 | number : "1.0-0"
                  }}
                  hours</span
                >
              </div>
              <div class="flex justify-between">
                <span class="w-24 opacity-50">Longest:</span>
                <span class="ml-2"
                  >{{
                    gamesSummary?.highestTime ?? 0 | number : "1.0-0"
                  }}
                  hours</span
                >
              </div>
            </div>
          </div>
        </ng-container>
        <button
          [routerLink]="['/player', playerName, 'completed-games']"
          class="inline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800"
        >
          2024 Completions
          <svg
            class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </section>

      <section
        class="min-w-[16rem] max-w-md rounded-lg border border-gray-700 bg-gray-800 p-6 shadow"
      >
        <div class="flex justify-between">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
            RGSC
          </h5>
          <div *ngIf="!rgscLoading" class="text-2xl">
            <span class="text-green-500">{{
              rgscSummary?.rgscsCompleted?.length ?? 0
            }}</span>
            / 11
          </div>
        </div>
        <ng-container *ngIf="rgscLoading">
          <main class="flex justify-center">
            <img
              src="../../../assets/infinite-spinner.svg"
              class="my-8 block h-8"
            />
          </main>
        </ng-container>
        <ng-container *ngIf="!rgscLoading">
          <div class="mb-3 flex flex-col font-normal text-gray-400">
            <div class="flex justify-between">
              <div>
                <span class="text-red-600">{{
                  rgscSummary?.rerollsRemaining ?? "--"
                }}</span>
                Rerolls Left
              </div>
              <div class="flex gap-x-0.5">
                <span class="text-xs opacity-25">Pool Size:</span>
                <span
                  [ngClass]="{
                    'text-xs opacity-50': true,
                    'text-red-600':
                      rgscSummary.currentRandoms?.poolSize < 50 &&
                      rgscSummary.currentRandoms[0]?.rgsc?.poolSize < 50
                  }"
                >
                  {{
                    rgscSummary.currentRandoms[0]?.rgsc?.poolSize ??
                      rgscSummary.currentRandoms?.poolSize ??
                      0
                  }}</span
                >
              </div>
            </div>
            <span class="mt-4">{{
              rgscSummary.currentRandoms[0]?.game?.title ?? "None drawn"
            }}</span>
            <div class="text-xs opacity-50">
              <div>
                {{
                  rgscSummary.currentRandoms[0]?.game?.siteRatio ?? 0
                    | number : "1.2-2"
                }}
                Ratio &nbsp; ~{{
                  rgscSummary.currentRandoms[0]?.game?.fullCompletionEstimate ??
                    0
                }}
                Hours
              </div>
            </div>
          </div>
        </ng-container>
        <button
          [routerLink]="['/player', playerName, 'rgsc']"
          class="inline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800"
        >
          All Randoms
          <svg
            class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </section>

      <section
        class="min-w-[14rem] max-w-md rounded-lg border border-gray-700 bg-gray-800 p-6 shadow"
      >
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
          Monthlies
        </h5>
        <div class="mb-3 font-normal text-gray-400">
          <h6 class="mb-2 text-lg font-semibold">
            Scorehammer 40,000 <span class="italic opacity-50">(Aug)</span>
          </h6>
          <ng-container *ngIf="monthlyLoading">
            <main class="flex justify-center">
              <img
                src="../../../assets/infinite-spinner.svg"
                class="my-8 block h-8"
              />
            </main>
          </ng-container>
          <ng-container *ngIf="!monthlyLoading">
            <div class="mb-3 text-sm font-normal text-gray-400">
              <div class="flex justify-between">
                <span class="w-48 opacity-50">Participation:</span>
                <span class="ml-2">
                  <span
                    *ngIf="monthlySummary?.participation"
                    class="font-semibold text-green-600"
                    >Yes</span
                  >
                  <span
                    *ngIf="!monthlySummary?.participation"
                    class="font-thin text-red-600"
                    >No</span
                  >
                </span>
              </div>
              <div class="flex justify-between">
                <span class="w-48 opacity-50">Tributes:</span>
                <span class="ml-2">
                  <span class="font-thin"
                    >{{ monthlySummary?.tributeCount || 0 }} / 5</span
                  >
                </span>
              </div>
              <div class="flex justify-between">
                <span class="w-48 opacity-50">Contributions:</span>
                <span class="ml-2">{{
                  monthlySummary?.achievementCount ?? 0 | number : "0.0-0"
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="w-48 opacity-50">Traitors:</span>
                <span class="ml-2 text-red-600"
                  >-{{
                    monthlySummary?.chaosCount ?? 0 | number : "0.0-0"
                  }}</span
                >
              </div>
              <div class="flex justify-between">
                <span class="w-48 opacity-50">Community Progress:</span>
                <span class="ml-2">
                  <span class="font-bold">{{
                    monthlySummary?.communityBonus | number
                  }}</span>
                </span>
              </div>
              <div class="flex justify-between">
                <span class="w-48 opacity-50">Month's Bonus Score:</span>
                <span class="ml-2">{{
                  monthlySummary?.totalPoints ?? 0 | number : "0.0-0"
                }}</span>
              </div>
            </div>
          </ng-container>
        </div>
        <button
          [routerLink]="['/player', playerName, 'rgsc']"
          class="inline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800 disabled:pointer-events-none disabled:opacity-60 disabled:grayscale"
          disabled
        >
          Details
          <svg
            class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </section>

      <section
        class="min-w-[14rem] max-w-md rounded-lg border border-gray-700 bg-gray-800 p-6 shadow"
      >
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
          Yearlies
        </h5>
        <ng-container *ngIf="yearlyLoading">
          <main class="flex justify-center">
            <img
              src="../../../assets/infinite-spinner.svg"
              class="my-8 block h-8"
            />
          </main>
        </ng-container>
        <ng-container *ngIf="!yearlyLoading">
          <div class="mb-3 text-sm font-normal text-gray-400">
            <div class="flex justify-between">
              <span class="w-32 opacity-50">Participation:</span>
              <span class="ml-2"
                ><span class="text-green-600">{{
                  yearlySummary?.participation ?? 0
                }}</span>
                / 11</span
              >
            </div>
            <div class="flex justify-between">
              <span class="w-32 opacity-50">Community Stars:</span>
              <span class="ml-2"
                ><span class="text-green-600"
                  >{{ yearlySummary?.commStarApproved ?? 0 }}
                  <span *ngIf="yearlySummary?.commStarUnapproved">
                    (+{{ yearlySummary?.commStarUnapproved }})</span
                  ></span
                >/ 20</span
              >
            </div>

            <div class="flex justify-between">
              <span class="w-32 opacity-50">The T.A.V.I.S.:</span>
              <span class="ml-2"
                ><span class="text-green-600"
                  >{{ yearlySummary?.tavisApproved ?? 0 }}
                  <span *ngIf="yearlySummary?.tavisUnapproved">
                    (+{{ yearlySummary?.tavisUnapproved }})</span
                  ></span
                >/ 20</span
              >
            </div>
            <div class="flex justify-between">
              <span class="w-32 opacity-50">Retirement Party:</span>
              <span class="ml-2"
                ><span class="text-green-600"
                  >{{ yearlySummary?.retirementApproved ?? 0 }}
                  <span *ngIf="yearlySummary?.retirementUnapproved">
                    (+{{ yearlySummary?.retirementUnapproved }})</span
                  ></span
                >/ 10</span
              >
            </div>
          </div>
        </ng-container>
        <button
          [routerLink]="['/player', playerName, 'yearlies']"
          class="inline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800"
        >
          Details
          <svg
            class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </section>

      <section
        class="w-80 rounded-lg border border-gray-700 bg-gray-800 p-6 shadow"
      >
        <div class="flex justify-between">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">ABC</h5>
          <div class="text-2xl" *ngIf="!abcLoading">
            <span class="text-green-500">{{ abcSummary?.length ?? 0 }}</span>
            / 25
          </div>
        </div>
        <ng-container *ngIf="abcLoading">
          <main class="flex justify-center">
            <img
              src="../../../assets/infinite-spinner.svg"
              class="my-8 block h-8"
            />
          </main>
        </ng-container>
        <ng-container *ngIf="!abcLoading">
          <div class="mb-3 text-xs font-normal text-gray-400">
            <div class="flex flex-wrap gap-1">
              <ng-container *ngFor="let letter of alphabet">
                <div
                  class="rounded-lg p-2 text-white"
                  [class]="!exists(letter) ? 'bg-none' : 'bg-green-700'"
                >
                  {{ letter }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <button
          [routerLink]="['/player', playerName, 'abc']"
          class="inline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800 disabled:pointer-events-none disabled:opacity-60 disabled:grayscale"
          disabled
        >
          Details
          <svg
            class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </section>

      <section
        class="min-w-[16rem] max-w-md rounded-lg border border-gray-700 bg-gray-800 p-6 shadow"
      >
        <div class="flex justify-between">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
            Odd Jobs
          </h5>
          <div class="text-2xl" *ngIf="!oddjobLoading">
            <span class="text-green-500">{{ oddjobSummary?.length ?? 0 }}</span>
            / 5
          </div>
        </div>
        <ng-container *ngIf="oddjobLoading">
          <main class="flex justify-center">
            <img
              src="../../../assets/infinite-spinner.svg"
              class="my-8 block h-8"
            />
          </main>
        </ng-container>
        <ng-container *ngIf="!oddjobLoading">
          <div class="mb-3 text-sm font-normal text-gray-400">
            <div class="flex justify-between">
              <span class="w-24 opacity-50">Arcade Racing:</span>
              <span class="ml-2">{{
                getOddJobGame("Arcade Racing") ?? "--"
              }}</span>
            </div>
            <div class="flex justify-between">
              <span class="w-24 opacity-50">Card & Board:</span>
              <span class="ml-2">{{
                getOddJobGame("Card & Board") ?? "--"
              }}</span>
            </div>
            <div class="flex justify-between">
              <span class="w-24 opacity-50">On Rails:</span>
              <span class="ml-2">{{ getOddJobGame("On Rails") ?? "--" }}</span>
            </div>
            <div class="flex justify-between">
              <span class="w-24 opacity-50">Real Time:</span>
              <span class="ml-2">{{ getOddJobGame("Real Time") ?? "--" }}</span>
            </div>
            <div class="flex justify-between">
              <span class="w-24 opacity-50">Stealth:</span>
              <span class="ml-2">{{ getOddJobGame("Stealth") ?? "--" }}</span>
            </div>
          </div>
        </ng-container>
      </section>

      <section
        class="min-w-[14rem] max-w-xl rounded-lg border border-gray-700 bg-gray-800 p-6 shadow"
      >
        <h5 class="mb-2 block text-2xl font-bold tracking-tight text-white">
          Misc Stats
        </h5>
        <ng-container *ngIf="miscLoading || rgscLoading">
          <main class="flex justify-center">
            <img
              src="../../../assets/infinite-spinner.svg"
              class="my-8 block h-8"
            />
          </main>
        </ng-container>
        <ng-container *ngIf="!miscLoading && !rgscLoading">
          <div class="mb-3 text-sm font-normal text-gray-400">
            <div class="flex justify-between">
              <span class="w-48 opacity-50">BCM Tenure:</span>
              <span class="ml-2"> {{ miscSummary?.length + 1 }} year(s)</span>
            </div>
            <div class="flex justify-between">
              <span class="w-48 opacity-50">Best Placement:</span>
              <span class="ml-2">
                <span class="text-xs opacity-50">
                  ({{ getBestPlacement(miscSummary)?.year }})
                </span>
                {{ getBestPlacement(miscSummary)?.placement ?? 0 | ordinal }}
              </span>
            </div>
            <div class="flex justify-between">
              <span class="w-48 opacity-50">Lifetime RGSC Completions:</span>
              <span class="ml-2"> {{ calcRgscCompletions(miscSummary) }}</span>
            </div>
            <div class="flex justify-between">
              <span class="w-48 opacity-50">Full Combos:</span>
              <span class="ml-2">{{ calcFullCombos(miscSummary) }}</span>
            </div>
          </div>
        </ng-container>
        <button
          [routerLink]="['/player', playerName, 'misc-stats']"
          class="inline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800"
        >
          More
          <svg
            class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </section>
    </div>
  </div>
</ng-container>
